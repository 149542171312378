import { css } from 'styled-components';
import { rem, mediaquery } from '@utils/grid';

const fontWeights = {
  thin: 100,
  xlight: 200,
  light: 300,
  book: 400,
  medium: 600,
  bold: 700,
  black: 900,
};

const lineHeight = (
  fontSizeInPixes: number,
  lineHeightInPixels: number
): number => {
  return lineHeightInPixels / fontSizeInPixes;
};

const linkReset = css`
  text-decoration: none;

  &:visited {
    color: inherit;
  }
`;

export const HeaderL = css`
  font-size: ${rem(38)};
  line-height: ${lineHeight(38, 46)};
  font-weight: ${fontWeights.medium};
  ${mediaquery.md(css`
    font-size: ${rem(60)};
    line-height: ${lineHeight(60, 72)};
  `)}
`;

export const HeaderM = css`
  font-size: ${rem(32)};
  line-height: ${lineHeight(32, 48)};
  font-weight: ${fontWeights.medium};
  ${mediaquery.md(css`
    font-size: ${rem(42)};
    line-height: ${lineHeight(42, 50)};
  `)}
`;

export const HeaderS = css`
  font-size: ${rem(24)};
  line-height: ${lineHeight(24, 29)};
  font-weight: ${fontWeights.medium};
  ${mediaquery.md(css`
    font-size: ${rem(32)};
    line-height: ${lineHeight(32, 38)};
  `)}
`;

export const HeaderXS = css`
  font-size: ${rem(19)};
  line-height: ${lineHeight(19, 23)};
  font-weight: ${fontWeights.medium};
  ${mediaquery.md(css`
    font-size: ${rem(28)};
    line-height: ${lineHeight(28, 34)};
  `)}
`;

export const SubHeaderM = css`
  font-size: ${rem(24)};
  line-height: ${lineHeight(24, 30)};
  font-weight: ${fontWeights.medium};
`;

export const SubHeaderS = css`
  font-size: ${rem(14)};
  line-height: ${lineHeight(14, 24)};
  font-weight: ${fontWeights.medium};
`;

export const LabelL = css`
  font-size: ${rem(19)};
  line-height: ${lineHeight(19, 28)};
  font-weight: ${fontWeights.medium};
`;

export const LabelM = css`
  font-size: ${rem(14)};
  line-height: ${lineHeight(14, 24)};
  font-weight: ${fontWeights.medium};
`;

export const LabelS = css`
  font-size: ${rem(14)};
  line-height: ${lineHeight(14, 24)};
  font-weight: ${fontWeights.book};
`;

export const LabelXS = css`
  font-size: ${rem(12)};
  line-height: ${lineHeight(12, 20)};
  font-weight: ${fontWeights.medium};
`;

export const LabelUnderlined = css`
  font-size: ${rem(14)};
  line-height: ${lineHeight(14, 30)};
  font-weight: ${fontWeights.book};
  text-decoration: underline;
`;

export const IntroText = css`
  font-size: ${rem(19)};
  line-height: ${lineHeight(19, 32)};
  font-weight: ${fontWeights.book};
`;

export const Body = css`
  font-size: ${rem(16)};
  line-height: ${lineHeight(16, 30)};
  font-weight: ${fontWeights.book};
`;

export const LinkText = css`
  font-size: ${rem(16)};
  line-height: ${lineHeight(16, 30)};
  font-weight: ${fontWeights.medium};
  ${linkReset}
`;

export const NavigationText = css`
  font-size: ${rem(10)};
  line-height: ${lineHeight(10, 11)};
  font-weight: ${fontWeights.bold};
  text-transform: uppercase;
  letter-spacing: ${rem(2)};
  ${mediaquery.md(css`
    font-size: ${rem(12)};
    line-height: ${lineHeight(12, 11)};
  `)}
  ${linkReset}
`;

export const Detail = css`
  font-size: ${rem(10)};
  line-height: ${lineHeight(10, 17)};
  font-weight: ${fontWeights.book};
  ${mediaquery.md(css`
    font-size: ${rem(12)};
    line-height: ${lineHeight(12, 18)};
  `)}
`;

export const FactLegalMention = css`
  font-size: ${rem(6)};
  line-height: ${lineHeight(6, 10)};
  font-weight: ${fontWeights.medium};
  letter-spacing: -1%;
`;
