import { useState, useEffect } from 'react';

import throttle from '@utils/throttle';

export enum DIRECTIONS {
  up = 'up',
  down = 'down',
}

type ScrollDirectionProps = {
  initialDirection: string;
  thresholdPixels: number;
  scrollThrottle: number;
};

const useScrollDirection = ({
  initialDirection,
  thresholdPixels,
  scrollThrottle,
}: ScrollDirectionProps): string => {
  const [scrollDir, setScrollDir] = useState(initialDirection);

  useEffect(() => {
    const threshold = thresholdPixels || 0;
    const throttleLimit = scrollThrottle || 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = (): void => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        // We haven't exceeded the threshold
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? DIRECTIONS.down : DIRECTIONS.up);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = (): void => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    const onScrollThrottled = throttle(onScroll, throttleLimit);
    window.addEventListener('scroll', onScrollThrottled, false);

    return (): void =>
      window.removeEventListener('scroll', onScrollThrottled, false);
  }, [scrollThrottle, thresholdPixels]);
  return scrollDir;
};

export default useScrollDirection;
