const throttle = (callback, limit): (() => void) => {
  let tick = false;
  return (): void => {
    if (!tick) {
      callback();
      tick = true;
      setTimeout(() => {
        tick = false;
      }, limit);
    }
  };
};

export default throttle;
