import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import styled, { css } from 'styled-components';
import { Container, Row, Col, Visible } from 'react-awesome-styled-grid';
import { InView } from 'react-intersection-observer';

import colors from '@utils/colors';
import { mediaquery } from '@utils/grid';
import { HeaderM } from '@utils/typography';

import Image, { ImageType, ImageSource } from '@components/Image';

import { PageContext, ReactNumberState, ReactBooleanState } from '@stores/Page';

const HeroContainer = styled.section`
  position: relative;
  /* put the Hero over the top of whatever comes below when using HeroSpace */
  z-index: 1;
`;

const CurvedContainer = styled.div`
  overflow: hidden;
`;

const Curved = styled.div`
  position: relative;
  background: ${colors.activiaGreen};
  width: 120%;
  left: -10%;
  height: 130vw;
  max-height: 70vh;
  overflow: hidden;
  border-radius: 0 0 90% 90% / 0 0 30vw 30vw;

  ${mediaquery.md(css`
    height: 45vw;
    max-height: initial;
  `)}

  ${mediaquery.lg(css`
    height: 35vw;
  `)}
`;

const Contained = styled.div`
  width: 100vw;
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  .gatsby-image-wrapper {
    height: 100%;
  }
`;

const ImgOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.03);
  z-index: 1;
`;

const TextContent = styled.div`
  display: flex;
  height: 100%;
  padding-bottom: 10vw;
  align-items: flex-end;
`;

const HeroHeaderMedium = styled.h1`
  ${HeaderM}
  color: ${colors.white};
  opacity: 0.9;
  margin: 8px;

  ${mediaquery.md(css`
    text-align: left;
    margin: 12px;
  `)}
`;

type HeroProps = {
  image: ImageSource;
  imageMobile: ImageSource;
  title: string;
};

const Hero: React.FC<HeroProps> = ({ image, imageMobile, title }) => {
  const [ioMargin, setIoMargin] = useState(0);
  const ctx = useContext(PageContext);

  const headerHeightState = useMemo(
    () => ctx.headerHeight as ReactNumberState,
    [ctx]
  );
  const transparentHeaderState = useMemo(
    () => ctx.transparentHeader as ReactBooleanState,
    [ctx]
  );
  const forceVisibleHeaderState = useMemo(
    () => ctx.forceVisibleHeader as ReactBooleanState,
    [ctx]
  );

  const [headerHeight] = headerHeightState;
  const [, setTransparentHeader] = transparentHeaderState;
  const [, setForceVisibleHeader] = forceVisibleHeaderState;

  useEffect(() => {
    setIoMargin(window.innerHeight - headerHeight);
  }, [headerHeight]);

  const onViewChange = useCallback(
    (inView): void => {
      setTransparentHeader(inView);
      setForceVisibleHeader(inView);
    },
    [setTransparentHeader, setForceVisibleHeader]
  );

  return (
    <InView rootMargin={`0px 0px -${ioMargin}px 0px`} onChange={onViewChange}>
      <HeroContainer>
        <CurvedContainer>
          <Curved>
            <Contained>
              <ImgOverlay />
              <Visible md lg xl>
                <Image type={ImageType.fluid} src={image} alt={title} />
              </Visible>
              <ImgOverlay />
              <Visible xs sm>
                <Image type={ImageType.fluid} src={imageMobile} alt={title} />
              </Visible>
            </Contained>

            <Contained>
              <TextContent>
                <Container>
                  <Row>
                    <Col md={8} lg={8} offset={{ lg: 3 }}>
                      <HeroHeaderMedium>{title}</HeroHeaderMedium>
                    </Col>
                  </Row>
                </Container>
              </TextContent>
            </Contained>
          </Curved>
        </CurvedContainer>
      </HeroContainer>
    </InView>
  );
};

export default Hero;
