import React, { useState, createContext } from 'react';

const initialState = {
  transparentHeader: true,
  visibleHeader: true,
  forceVisibleHeader: false,
  headerHeight: 93,
};

export type ReactBooleanState = [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>
];
export type ReactNumberState = [
  number,
  React.Dispatch<React.SetStateAction<number>>
];

type ContextProps = {
  transparentHeader: ReactBooleanState;
  visibleHeader: ReactBooleanState;
  forceVisibleHeader: ReactBooleanState;
  headerHeight: ReactNumberState;
};

export const PageContext = createContext<Partial<ContextProps>>({});

export const PageProvider: React.FC = ({ children }) => {
  const pageState = {
    transparentHeader: useState(initialState.transparentHeader),
    visibleHeader: useState(initialState.visibleHeader),
    forceVisibleHeader: useState(initialState.forceVisibleHeader),
    headerHeight: useState(initialState.headerHeight),
  };

  return (
    <PageContext.Provider value={pageState}>{children}</PageContext.Provider>
  );
};
