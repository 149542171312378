const colors = {
  white: '#fff',
  translucidWhite: 'rgba(255,255,255,0.4)',
  translucidBlack: 'rgba(0,0,0,0.10)',
  mediumShadow: 'rgba(0,0,0,0.4)',
  activiaGradient: ['#008830', '#03c046'],
  lightShadowGradient: ['rgba(0,0,0,0.4)', 'rgba(0,0,0,0)'],
  mediumShadowGradient: ['rgba(0,0,0,0.5)', 'rgba(0,0,0,0)'],
  cardFadeGradient: ['#fff', 'rgba(255,255,255,0)'],
  activiaGreenGradient: ['rgba(2, 186, 68, 1)', 'rgba(2, 186, 68, 0)'],

  activiaGreen: '#02ba44',
  mediumGreen: '#1d5b34',
  darkGreen: '#043e19',

  lighterGrey: '#f0f0f0',
  lightGrey: '#dedede',
  mediumGrey: '#adadad',
  darkGrey: '#666666',
  darkerGrey: '#434343',

  black: '#333333',
  beige: '#f0ebde',
  yellow: '#ebd87d',
  pink: '#eb8a7d',

  blue: '#61a0f4',
  outline: '',
};

colors.outline = `0 0 3px 2px ${colors.blue}`;

export default colors;
