import { css, FlattenSimpleInterpolation } from 'styled-components';

const BASE_FONT_SIZE = 16;

export const rem = (px: number, addUnit = true): string | number => {
  const r = px / BASE_FONT_SIZE;
  return addUnit ? `${r}rem` : r;
};

type Breakpoints = {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
};

const columns = {
  xs: 8,
  sm: 8,
  md: 16,
  lg: 32,
  xl: 32,
};

const gutterWidth = {
  xs: rem(20, false),
  sm: rem(20, false),
  md: rem(20, false),
  lg: rem(20, false),
  xl: rem(20, false),
};

const paddingWidth = {
  xs: rem(38, false),
  sm: rem(38, false),
  md: rem(42, false),
  lg: rem(26, false),
  xl: rem(26, false),
};

const container = {
  xs: 'full', // 'full' = max-width: 100%
  sm: 'full', // 'full' = max-width: 100%
  md: 'full', // 'full' = max-width: 100%
  lg: 'full', // 'full' = max-width: 100%
  xl: 'full', // 'full' = max-width: 100%
};

export const breakpoints: Breakpoints = {
  xs: 1,
  sm: rem(480, false) as number,
  md: rem(768, false) as number,
  lg: rem(1152, false) as number,
  xl: rem(1440, false) as number,
};

export const config = {
  columns,
  gutterWidth,
  paddingWidth,
  container,
  breakpoints,
};

type Mediaquery = (
  css?: FlattenSimpleInterpolation
) => FlattenSimpleInterpolation;

type Mediaqueries = {
  xs: Mediaquery;
  sm: Mediaquery;
  md: Mediaquery;
  lg: Mediaquery;
  xl: Mediaquery;
};

const buildMediaQuery = (breakpoint: string): Mediaquery => {
  return (styles): FlattenSimpleInterpolation => css`
    @media (min-width: ${breakpoints[breakpoint]}em) {
      ${styles}
    }
  `;
};

export const mediaquery: Mediaqueries = {
  xs: buildMediaQuery('xs'),
  sm: buildMediaQuery('sm'),
  md: buildMediaQuery('md'),
  lg: buildMediaQuery('lg'),
  xl: buildMediaQuery('xl'),
};
