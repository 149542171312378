import React, { useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Image, { ImageType, ImageSource } from '@components/Image';

import colors from '@utils/colors';
import { mediaquery } from '@utils/grid';

import useScrollDirection, { DIRECTIONS } from '@hooks/scrollDirection';

import { PageContext, ReactBooleanState } from '@stores/Page';

export const BEZIER = 'cubic-bezier(0.785, 0.135, 0.15, 0.86)';

export enum HEADER_HEIGHT {
  mobile = 73,
  desktop = 93,
}

const Header = styled.header<{ green: boolean; visible: boolean }>`
  position: fixed;
  z-index: 2;
  width: 100%;
  transition: transform 300ms ease-in-out;
  ${({ visible }): any =>
    !visible &&
    css`
      transform: translateY(-150%);
    `}

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 150px;
    background: linear-gradient(
      to bottom,
      ${colors.mediumShadowGradient[0]},
      ${colors.mediumShadowGradient[1]}
    );
    opacity: ${({ green }): string => (green ? '0' : '1')};
    transition: opacity 300ms;
    pointer-events: none;
  }
`;

const CurvedContainer = styled.div`
  overflow: hidden;
`;

const Curved = styled.div<{ transparent: boolean }>`
  position: relative;
  height: ${HEADER_HEIGHT.mobile}px;
  border-radius: 0 0 100% 100% / 0 0 40px 40px;
  width: 120%;
  left: -10%;
  padding: 0 10%;
  overflow: hidden;

  ${mediaquery.lg(css`
    height: ${HEADER_HEIGHT.desktop}px;
  `)}

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50px;
    background: linear-gradient(
      to top,
      ${colors.activiaGreenGradient[0]} 0%,
      ${colors.activiaGreenGradient[1]} 100%
    );
    opacity: ${({ transparent }): number => (transparent ? 0 : 1)};
    transition: opacity 300ms ${BEZIER} 300ms;
  }
`;

const Contained = styled.div<{ transparent: boolean }>`
  width: 100vw;
  height: 100%;
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(
      to top,
      ${colors.activiaGradient[0]} 0%,
      ${colors.activiaGradient[1]} 100%
    );
    opacity: ${({ transparent }): number => (transparent ? 0 : 1)};
    transition: opacity 300ms ${BEZIER} 300ms;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: ${colors.activiaGreen};
    opacity: ${({ transparent }): number => (transparent ? 0 : 1)};

    transition: opacity 300ms ${BEZIER} 300ms;
  }
`;

export type NavProps = {
  logo: ImageSource;
};

const Nav: React.FC<NavProps> = ({ logo }) => {
  const ctx = useContext(PageContext);
  const headerstate = ctx.transparentHeader as ReactBooleanState;
  const visibleHeaderState = ctx.visibleHeader as ReactBooleanState;
  const [transparentHeader] = headerstate;
  const [visibleHeader, setVisibleHeader] = visibleHeaderState;

  const scrollDir = useScrollDirection({
    initialDirection: DIRECTIONS.up,
    thresholdPixels: 10,
    scrollThrottle: 100,
  });

  useEffect(() => {
    setVisibleHeader(scrollDir === DIRECTIONS.up);
  }, [scrollDir, setVisibleHeader]);

  return (
    <Header green={!transparentHeader} visible={visibleHeader}>
      <CurvedContainer>
        <Curved transparent={transparentHeader}>
          <Contained transparent={transparentHeader}>
            <Image type={ImageType.fixed} src={logo} alt="" />
          </Contained>
        </Curved>
      </CurvedContainer>
    </Header>
  );
};

export default Nav;
