import React, { memo } from 'react';
import styled, { css } from 'styled-components';

import colors from '@utils/colors';
import { mediaquery } from '@utils/grid';
import { Body, SubHeaderM } from '@utils/typography';
import sortStrings from '@utils/sortStrings';

import Image, { ImageSource, ImageType } from '@components/Image';

const Section = styled.section`
  margin: 0 auto;
  margin-bottom: 74px;
  padding: 0 32px;
  max-width: 1124px;
`;

const Region = styled.h3`
  ${SubHeaderM}

  padding-bottom: 16px;
  border-bottom: 1px solid ${colors.darkGrey};
  margin-bottom: 24px;
`;

const MarketWrapper = styled.ol`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const LinkWrapper = styled.li`
  width: 100%;

  ${mediaquery.md(css`
    width: 50%;
  `)}
`;

const FlagWrapper = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 100%;
  overflow: hidden;
  display: inline-block;
  margin-right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.lighterGrey};
`;

const Link = styled.a<{ className?: string }>`
  ${Body}
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 350px;
  height: 50px;
  padding: 12px;
  transition: background 0.3s ease-out;

  &:hover,
  &:focus,
  &.active {
    background: ${colors.lighterGrey};
    border-radius: 12px;
  }
`;

export type Market = {
  name: string;
  countryCode: string;
  url: string;
  flag: ImageSource;
};

type Region = {
  region: string;
  markets: Market[];
};

export type Regions = Region[];

type CountrySelectorProps = {
  regions: Regions;
  inferredMarket: Market | null;
  onSelect: (countryCode: string) => void;
};

const CountrySelector: React.FC<CountrySelectorProps> = ({
  regions,
  inferredMarket,
  onSelect,
}) => {
  const sortedRegions = regions
    .sort((a, b) => sortStrings(b.region, a.region))
    .map(({ region, markets }) => ({
      region,
      markets: markets.sort((a, b) => sortStrings(b.name, a.name)),
    }));

  return (
    <>
      {sortedRegions.map(({ region, markets }) => (
        <Section key={region}>
          <Region>{region}</Region>
          <MarketWrapper>
            {markets.map(({ countryCode, url, name, flag }) => (
              <LinkWrapper key={url}>
                <Link
                  href={url}
                  onClick={(): void => onSelect(countryCode)}
                  className={
                    countryCode === inferredMarket?.countryCode
                      ? 'active'
                      : undefined
                  }
                >
                  <FlagWrapper>
                    <Image
                      type={ImageType.fixed}
                      src={flag}
                      alt={`${name} flag`}
                    />
                  </FlagWrapper>
                  {name}
                </Link>
              </LinkWrapper>
            ))}
          </MarketWrapper>
        </Section>
      ))}
    </>
  );
};

export default memo(CountrySelector);
