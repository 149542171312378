import React from 'react';
import Img, { FluidObject, FixedObject } from 'gatsby-image';

export enum ImageType {
  fixed = 'fixed',
  fluid = 'fluid',
}

type GatsbyImageObject = {
  childImageSharp: {
    fixed?: FixedObject | FixedObject[];
    fluid?: FluidObject | FluidObject[];
  };
};

export type ImageSource = GatsbyImageObject | string;

type ImageProps = {
  type: ImageType;
  resolutions?: FixedObject;
  sizes?: FluidObject;
  src: ImageSource;
  fadeIn?: boolean;
  durationFadeIn?: number;
  title?: string;
  alt?: string;
  className?: string | object;
  critical?: boolean;
  crossOrigin?: string | boolean;
  style?: object;
  imgStyle?: object;
  placeholderStyle?: object;
  placeholderClassName?: string;
  backgroundColor?: string | boolean;
  onLoad?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError?: (event: any) => void;
  onStartLoad?: (param: { wasCached: boolean }) => void;
  Tag?: string;
  itemProp?: string;
  loading?: `auto` | `lazy` | `eager`;
  draggable?: boolean;
};

const Image: React.FC<ImageProps> = ({ type, src, ...rest }) => {
  if (typeof src === 'string') {
    return <img src={src} alt="" />;
  }

  const config = {
    [type]: src.childImageSharp[type],
  };
  return <Img {...config} {...rest} />;
};

export default Image;
