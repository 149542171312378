import React from 'react';
import styled, { css } from 'styled-components';

import colors from '@utils/colors';
import { mediaquery } from '@utils/grid';
import { Detail } from '@utils/typography';

import Facebook from '@icons/facebook.svg';
import Instagram from '@icons/instagram.svg';
import Pinteres from '@icons/pinterest.svg';
import Twitter from '@icons/twitter.svg';

const Wrapper = styled.footer`
  width: 100%;
  display: flex;
  padding: 40px 64px;
  align-items: center;
  justify-content: space-between;
  flex-direction: column-reverse;

  ${mediaquery.md(css`
    flex-direction: row;
  `)}
`;

const Copy = styled.div`
  ${Detail}
  color: ${colors.darkGrey};
  margin-top: 48px;

  ${mediaquery.md(css`
    margin-top: 0;
  `)}
`;

const LinksContainer = styled.div`
  display: flex;
`;

const Link = styled.a`
  margin: 0 8px;
`;

const TextWrapper = styled.span`
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
`;

enum SocialPlatforms {
  fb = 'facebook',
  insta = 'instagram',
  pinteres = 'pinterest',
  twitter = 'twitter',
}

export type FooterProps = {
  copyright: string;
  fbURL: string;
  instaURL: string;
  pinterestURL: string;
  twitterURL: string;
};

const Footer: React.FC<FooterProps> = ({
  copyright,
  fbURL,
  instaURL,
  pinterestURL,
  twitterURL,
}) => {
  const socialLinks = [
    { name: SocialPlatforms.fb, url: fbURL, Component: Facebook },
    { name: SocialPlatforms.insta, url: instaURL, Component: Instagram },
    { name: SocialPlatforms.pinteres, url: pinterestURL, Component: Pinteres },
    { name: SocialPlatforms.twitter, url: twitterURL, Component: Twitter },
  ].filter(({ url }) => url);

  return (
    <Wrapper>
      <Copy>{copyright}</Copy>
      <LinksContainer>
        {socialLinks.map(({ name, url, Component }) => (
          <Link href={url} target="_blank" rel="noopener noreferrer" key={url}>
            <IconWrapper>
              <Component />
            </IconWrapper>
            <TextWrapper>{name}</TextWrapper>
          </Link>
        ))}
      </LinksContainer>
    </Wrapper>
  );
};

export default Footer;
