import React, { useEffect, useState, useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Visible } from 'react-awesome-styled-grid';
import geolocator from 'geolocator';
import { useInView } from 'react-intersection-observer';

import { ImageSource } from '@components/Image';
import Hero from '@components/Hero';
import Button, { SIZES, TYPES } from '@components/Button';
import CountrySelector, { Regions, Market } from '@components/CountrySelector';

import colors from '@utils/colors';
import { mediaquery } from '@utils/grid';
import { Body, HeaderM } from '@utils/typography';

enum LocalStorageKeys {
  savedMarket = 'activia_selected_market',
}

const StickyDetector = styled.div`
  height: 1px;
  width: 100;
`;

const StickyWrapper = styled.div<{ isSticky: boolean }>`
  background: ${colors.white};
  ${mediaquery.md(css`
    position: sticky;
    top: 0;

    z-index: 1;
  `)}

  ${({ isSticky }): any =>
    isSticky &&
    mediaquery.md(css`
      box-shadow: 0 4px 2px -2px ${colors.translucidBlack};
    `)}
`;

const TextContainer = styled.div`
  text-align: center;
  padding: 40px 32px;
  margin: 0 auto;

  ${mediaquery.md(css`
    text-align: left;
    padding: 112px 32px;
    max-width: 1124px;
  `)}
`;

const MobileButtonWrapper = styled.div`
  padding: 20px 0;
`;

const TextColumns = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  ${mediaquery.md(css`
    flex-direction: row;
  `)}
`;

const SubHead = styled.h2`
  ${HeaderM}
  color: ${colors.mediumGreen};
  margin-bottom: 12px;
`;

const Copy = styled.p`
  ${Body}
  max-width: 300px;
  margin: 0 auto;

  ${mediaquery.md(css`
    margin: 0;
  `)}
`;

export type PageContentProps = {
  heroDesktop: ImageSource;
  heroMobile: ImageSource;
  copy: {
    welcome: string;
    generic: {
      heading: string;
      subheading: string;
    };
    geo: {
      heading: string;
      subheading: string;
    };
  };
  confirm: string;
  regions: Regions;
};

const PageContent: React.FC<PageContentProps> = ({
  heroDesktop,
  heroMobile,
  copy,
  confirm,
  regions,
}) => {
  const [inferredMarket, setInferredMarket] = useState<Market | null>(null);
  const [isSticky, setIsSticky] = useState(false);
  const [ref, inView] = useInView({ threshold: [0, 1] });
  const buttonRef = useRef<HTMLAnchorElement>();

  useEffect(() => {
    setTimeout(() => {
      if (inferredMarket && buttonRef.current) {
        buttonRef.current.focus();
      }
    }, 0);
  }, [inferredMarket]);

  useEffect(() => {
    setIsSticky(!inView);
  }, [inView]);

  useEffect(() => {
    // Only attempt geo location on client
    if (window) {
      const markets = regions.map(m => m.markets).flat();
      const savedMarket = localStorage.getItem(LocalStorageKeys.savedMarket);

      if (savedMarket) {
        const market = markets.filter(m => m.countryCode === savedMarket)[0];

        if (market) {
          // redirect
          window.location.href = market.url;
          return;
        }
      }

      geolocator.config({
        language: 'en',
      });

      const options = {
        enableHighAccuracy: true,
        timeout: 3000,
        maximumWait: 3000, // max wait time for desired accuracy
        maximumAge: 0,
        desiredAccuracy: 30, // meters
        fallbackToIP: true, // fallback to IP if Geolocation fails or rejected
      };
      geolocator.locate(options, (_, l) => {
        if (l) {
          const market =
            markets.filter(m => m.countryCode === l.address.countryCode)[0] ||
            null;
          if (market) {
            setInferredMarket(market);
          }
        }
      });
    }
  }, [regions]);

  const getGeoText = useCallback(
    (text): string => {
      const regexp = new RegExp('{{country}}', 'g');
      return text.replace(regexp, inferredMarket?.name || '');
    },
    [inferredMarket]
  );

  const onSelect = useCallback((countryCode: string): void => {
    localStorage.setItem(LocalStorageKeys.savedMarket, countryCode);
  }, []);

  return (
    <main>
      <Hero title={copy.welcome} image={heroDesktop} imageMobile={heroMobile} />
      <StickyDetector ref={ref} />
      <StickyWrapper isSticky={isSticky}>
        <TextContainer>
          <TextColumns>
            {inferredMarket ? (
              <>
                <div>
                  <SubHead>{getGeoText(copy.geo.heading)}</SubHead>
                  <Visible xs sm>
                    <MobileButtonWrapper>
                      <Button
                        ref={buttonRef}
                        type={TYPES.link}
                        size={SIZES.medium}
                        href={inferredMarket.url}
                        onClick={(): void =>
                          onSelect(inferredMarket.countryCode)
                        }
                      >
                        {confirm}
                      </Button>
                    </MobileButtonWrapper>
                  </Visible>
                  <Copy>{getGeoText(copy.geo.subheading)}</Copy>
                </div>
                <Visible md lg xl>
                  <Button
                    ref={buttonRef}
                    type={TYPES.link}
                    size={SIZES.medium}
                    href={inferredMarket.url}
                    onClick={(): void => onSelect(inferredMarket.countryCode)}
                  >
                    {confirm}
                  </Button>
                </Visible>
              </>
            ) : (
              <div>
                <SubHead>{copy.generic.heading}</SubHead>
                <Copy>{copy.generic.subheading}</Copy>
              </div>
            )}
          </TextColumns>
        </TextContainer>
      </StickyWrapper>
      <nav>
        <CountrySelector
          regions={regions}
          inferredMarket={inferredMarket}
          onSelect={onSelect}
        />
      </nav>
    </main>
  );
};

export default PageContent;
