import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import colors from '@utils/colors';
import { mediaquery } from '@utils/grid';
import { Body, LabelS } from '@utils/typography';

export enum SIZES {
  medium = 'medium',
  small = 'small',
}

export enum TYPES {
  link = 'a',
  button = 'button',
}

const baseStyles = {
  [SIZES.medium]: Body,
  [SIZES.small]: LabelS,
};

const heightMap = {
  [SIZES.medium]: '50px',
  [SIZES.small]: '40px',
};

const paddingMap = {
  [SIZES.medium]: '0 30px',
  [SIZES.small]: '0 24px',
};

const Outer = styled.div<{ size: string }>`
  background: linear-gradient(
    to top,
    ${colors.activiaGradient[0]} 0%,
    ${colors.activiaGradient[1]} 100%
  );
  height: ${({ size }): string => heightMap[size || SIZES.medium]};
  padding: ${({ size }): string => paddingMap[size || SIZES.medium]};
  border-radius: 999px;
  color: ${colors.white};
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  ${mediaquery.sm(css`
    width: auto;
  `)}

  /* forcing flex as the component can be used as an a tag */
  display: flex;
  align-items: center;
  justify-content: center;

  /* prevent weird text overflow behaviour */
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;

  &:focus {
    outline: none;
    box-shadow: ${colors.outline};
  }
`;

const Inner = styled.span<{ styles: any }>`
  ${({ styles }): any => styles}
`;

type ButtonProps = {
  ref?: React.MutableRefObject<
    HTMLAnchorElement | HTMLButtonElement | undefined
  >;
  type?: any;
  size?: SIZES;
  href?: string;
  onClick?: () => void;
  children: React.ReactNode;
};

const Button: React.FC<ButtonProps> = forwardRef(
  ({ type, size, children, ...rest }, ref) => {
    return (
      <Outer ref={ref} as={type || TYPES.button} size={size} {...rest}>
        <Inner styles={baseStyles[size || SIZES.medium]}>{children}</Inner>
      </Outer>
    );
  }
);

export default Button;
