import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset-advanced';
import './fonts/fonts.css';

const GlobalStyle = createGlobalStyle`
  ${reset}

  html {
    font-family: "Gotham", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    background: #ffffff;
  }

  button {
    font-family: inherit;
    border: 0 none;
    padding: 0;
    background: inherit;
  }

  a {
    color: inherit;
  }
`;

export default GlobalStyle;
