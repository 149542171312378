import React from 'react';
import { FixedObject } from 'gatsby-image';
import { Helmet } from 'react-helmet';

import favicon from '../img/favicon.ico';

export type MetaProps = {
  title: string;
  description: string;
  image: {
    childImageSharp: {
      fixed: FixedObject;
    };
  };
  siteName: string;
  fbPageId?: string;
  twitterAuthor?: string;
};

const Meta: React.FC<MetaProps> = ({
  title,
  description,
  image,
  siteName,
  fbPageId,
  twitterAuthor,
}) => {
  const siteURL = 'https://activia.com';
  const img = image && image.childImageSharp.fixed;
  const imgUrl = img && `${siteURL}${img.src}`;
  const imgUrlArr = imgUrl && imgUrl.split('.');
  const imgType = imgUrl && `image/${imgUrlArr[imgUrlArr.length - 1]}`;
  const meta = [
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `theme-color`,
      content: `#FFFFFF`,
    },
    {
      property: `og:locale`,
      content: 'en-US',
    },
    {
      property: `og:url`,
      content: siteURL,
    },
    {
      name: `twitter:url`,
      content: siteURL,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `description`,
      content: description,
    },
    {
      property: `og:description`,
      content: description,
    },
    {
      name: `twitter:description`,
      content: description,
    },
    ...(imgUrl
      ? [
          {
            property: `og:image`,
            content: imgUrl,
          },
          {
            property: `og:image:width`,
            content: img.width,
          },
          {
            property: `og:image:height`,
            content: img.height,
          },
          {
            property: `og:image:type`,
            content: imgType,
          },
          {
            name: `twitter:image`,
            content: imgUrl,
          },
        ]
      : []),
    {
      property: `og:site_name`,
      content: siteName,
    },
  ];

  if (fbPageId) {
    meta.push({
      name: `fb:page_id`,
      content: fbPageId,
    });
  }

  if (twitterAuthor) {
    meta.push({
      name: `twitter:creator`,
      content: twitterAuthor,
    });
    meta.push({
      name: `twitter:site`,
      content: twitterAuthor,
    });
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en-US',
      }}
      title={title}
      link={[{ rel: 'shortcut icon', type: 'image/ico', href: `${favicon}` }]}
    >
      {meta.map(({ name, property, content }) => (
        <meta
          name={name}
          property={property}
          content={content.toString()}
          key={property || name}
        />
      ))}
    </Helmet>
  );
};

export default Meta;
