import React from 'react';
import { ThemeProvider } from 'styled-components';

import GlobalStyles from '@styles/GlobalStyles';

import { config } from '@utils/grid';

const gridConfig = {
  mediaQuery: 'only screen',
  ...config,
};

const theme = {
  awesomegrid: gridConfig,
};

const StyleProvider: React.FC = ({ children }) => (
  <>
    <GlobalStyles />
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </>
);

export default StyleProvider;
