import React from 'react';
import { graphql } from 'gatsby';

import { PageProvider } from '@stores/Page';
import StyleProvider from '@stores/Style';

import Meta, { MetaProps } from '@components/Meta';
import Nav, { NavProps } from '@components/Nav';
import PageContent, { PageContentProps } from '@components/PageContent';
import Footer, { FooterProps } from '@components/Footer';

type HomepageContent = {
  nav: NavProps;
  content: PageContentProps;
  footer: FooterProps;
};

interface HPFrontmatter extends HomepageContent {
  meta: MetaProps;
}

type HomepageProps = {
  data: {
    markdownRemark: {
      frontmatter: HPFrontmatter;
    };
  };
};

export const HomepageLayout: React.FC<HomepageContent> = ({
  nav,
  content,
  footer,
}) => {
  return (
    <PageProvider>
      <Nav {...nav} />
      <PageContent {...content} />
      <Footer {...footer} />
    </PageProvider>
  );
};

const Homepage: React.FC<HomepageProps> = ({ data }) => {
  const { meta, ...rest } = data.markdownRemark.frontmatter;

  return (
    <StyleProvider>
      <Meta {...meta} />
      <HomepageLayout {...rest} />
    </StyleProvider>
  );
};

export default Homepage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "homepage" } }) {
      frontmatter {
        meta {
          title
          description
          image {
            childImageSharp {
              fixed(width: 1200) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
          siteName
          fbPageId
          twitterAuthor
        }
        nav {
          logo {
            childImageSharp {
              fixed(width: 160) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
        content {
          heroMobile {
            childImageSharp {
              fluid(maxWidth: 768) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          heroDesktop {
            childImageSharp {
              fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          copy {
            welcome
            geo {
              heading
              subheading
            }
            generic {
              heading
              subheading
            }
          }
          confirm
          regions {
            region
            markets {
              name
              countryCode
              url
              flag {
                childImageSharp {
                  fixed(height: 26) {
                    ...GatsbyImageSharpFixed_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
        footer {
          copyright
          fbURL
          instaURL
          pinterestURL
          twitterURL
        }
      }
    }
  }
`;
